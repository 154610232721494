<template>
<DefaultLayout>
    <template v-slot:header>
        <Header :type="'menu'" :headerTitle=title :balanceStatus="'yes'" />
    </template>
    <template v-slot:hero>
        <HeroMain :page="'rankingAndReward'" :myRank="myRank"></HeroMain>
    </template>
    <template v-slot:content>
        <section class="wrapper-content leaderboard">
            <div class="container">
                <div v-if="loaderActive" class="main-card">
                    <loader :active="loaderActive" />
                </div>
                <div v-else class="main-card">
                    <h5 class="text-center"><img class="mr-2" src="../assets/img/ranking-icon-black.svg" alt="Ranking Icon">Leaderboard</h5>
                    <table class="table table-striped leaderboard-table m-0">
                        <thead>
                            <tr>
                                <th scope="col">Rank</th>
                                <th scope="col">User</th>
                                <th scope="col">Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="rankState in leaderBoard" :key="rankState.id">
                                <td>#{{rankState.position}}</td>
                                <td>{{rankState.name}}</td>
                                <td>{{rankState.rank}}</td>
                            </tr>
                            <tr>
                                <td colspan="3"><span>List only shows TOP 10</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </section>
    </template>
    <template v-slot:modals>
        <!-- Modal -->
        <div class="modal fade ranking-reward-modal" id="rankingRewardModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header justify-content-center border-0">
                        <h1 class="modal-title">Ranking Reward</h1>
                    </div>
                    <div class="modal-body">
                        <table class="table table-striped leaderboard-table m-0">
                            <tbody>
                                <tr v-for="reward in rankingRewards" :key="reward.id">
                                    <td>
                                        <div>
                                            {{ reward.rank }} <br>
                                            <small v-if="reward.description">{{ reward.description }}</small>
                                        </div>

                                    </td>
                                    <td>
                                        <button class="btn btn-full" :class="reward.rm >= 1000 ? 'btn-warning':'' ,
                                             reward.rm < 1000 && reward.rm >= 500 ? 'btn-primary':'' ,
                                            reward.rm < 500 && reward.rm >= 250 ? 'btn-success':'' ,
                                             reward.rm < 250 && reward.rm >= 100 ? 'btn-danger':''">
                                            RM {{reward.rm}}
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div class="p-4">
                        <button type="button" class="btn btn-purple btn-full mt-2" data-dismiss="modal">Close Tab</button>
                    </div>
                </div>
            </div>
        </div>
    </template>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import SidebarNav from '@/components/SidebarNav.vue'
import Header from '@/components/Header.vue'
import HeroMain from '@/components/HeroMain.vue'
import Loader from '../components/Loader.vue'
import config from '../config'

export default {
    name: 'RankingAndReward',
    data() {
        return {
            menuShow: false,
            userLogin: true,
            loaderActive:true,
            myRank: "",
            title: "",
            leaderBoard: [],
            rankingRewards: [],
        }
    },
    components: {
        DefaultLayout,
        SidebarNav,
        HeroMain,
        Header,
        Loader
    },
    mounted() {

        this.getRewardData();

        var self = this;

        self.baseImageUrl = config.$baseImageUrl

        const token = self.$cookie.getCookie('sanctum_token');

        this.axios.get(config.$baseUrl + 'leaderboard?record=10', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(function (response) {
                
                self.leaderBoard = response.data;
                self.loaderActive=false;

            });

        this.axios.get(config.$baseUrl + 'myRank?user_id=' + this.$cookie.getCookie('user_id'), {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(function (response) {
                this.myRank = response.data;

            }.bind(this))
    },
    methods: {
        toggleMenu() {
            this.menuShow = true
        },

        getRewardData() {

            const token = this.$cookie.getCookie('sanctum_token');

            this.axios.get(config.$baseUrl + 'get-reward-data', {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {

                    this.rankingRewards = response.data;

                }.bind(this));

        }
    },

    computed: {}
}
</script>
