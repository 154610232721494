<template>
<DefaultLayout>
    <template v-slot:header>
        <Header :type="'menu'" :headerTitle=title :balanceStatus="'yes'" />
    </template>
    <template v-slot:hero>
        <HeroMain :page="'contactUs'"></HeroMain>
    </template>
    <template v-slot:content>
        <section class="wrapper-content">
            <div class="container">
                <div class="main-card normal">
                    <br>
                    <b class="need-help">Need help ? Contact us with button below</b>
                    <center>
                        <a href="https://www.ox88reward.com/contact-us/" target="_blank" class="btn btn-contact-us btn-primary">Contact Us</a>
                    </center>
                </div>
            </div>
        </section>
    </template>
    <template v-slot:modals>
        <!-- Modal -->
    </template>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
// @ is an alias to /src
import SidebarNav from '@/components/SidebarNav.vue'
import Header from '@/components/Header.vue'
import HeroMain from '@/components/HeroMain.vue'
import config from '../config'

export default {
    name: 'Home',
    data() {
        return {
            menuShow: false,
            userLogin: true,
            title: "",
            $baseUrl: '',
            information: []
        }
    },
    components: {
        DefaultLayout,
        SidebarNav,
        HeroMain,
        Header,
    },
    mounted() {
        this.$baseUrl = config.$baseUrl;
        this.contactUs();
    },
    created() {
        //    
        // this.countDownTimer()
    },
    methods: {
        toggleMenu() {
            this.menuShow = true
        },
        contactUs() {
            this.baseImageUrl = config.$baseImageUrl

            const token = this.$cookie.getCookie('sanctum_token');

            this.axios.get(config.$baseUrl + 'contact-us', {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {

                    this.information = response.data;

                }.bind(this));
        }
    },

}
</script>
