<template>
<div class="sidebar-nav" :class="[menuShow ? 'show': '']">
    <div v-show="userState">
        <div class="navbar-header">
            <div class="media">
                <img v-if="this.$cookie.getCookie('user_image')" :src="baseImageUrl+this.$cookie.getCookie('user_image')" class="mr-3" alt="...">
                <img v-else src="../assets/img/profile-images/profile-img.svg" class="mr-3" alt="...">
                <div class="media-body">
                    <p>Hello</p>
                    <h5 class="mt-0">{{ this.$cookie.getCookie('user_name')}}</h5>
                </div>
            </div>
            <a href="#" @click="menuShow=false" class="menu-toggle-btn"><img src="/img/arrow-back-icon.b4c617df.svg" alt="toggle-icon"></a>
        </div>
        <div class="navbar-body">
            <ul class="main-menu">
                <li>
                    <router-link @click="menuShow = false" to="/"><img src="../assets/img/menu-icons/home-icon.svg" alt="Home">Home
                    </router-link>
                </li>
                <li>
                    <router-link @click="menuShow = false" to="/ranking-and-reward"><img src="../assets/img/menu-icons/ranking-icon.svg" alt="Ranking">Ranking & Reward
                    </router-link>
                </li>
                <li>
                    <router-link @click="menuShow = false" to="/my-account"><img src="../assets/img/menu-icons/account-icon.svg" alt="">My Account
                    </router-link>
                </li>
                <li>
                    <router-link @click="menuShow = false" to="/my-wallet"><img src="../assets/img/menu-icons/wallet-icon.svg" alt="">My Wallet
                    </router-link>
                </li>
                <li>
                    <router-link @click="menuShow = false" to="/result-history"><img src="../assets/img/menu-icons/history-icon.svg" alt="">Result History
                    </router-link>
                </li>
                <li>
                    <a href="http://www.ox88reward.com/fifa2022en/#fund" target="_blank"><img class="claim-fund-img" src="../assets/img/claim-fund.svg" alt="">Claim Fund with deposit</a>
                </li>
                <li>
                    <router-link @click="menuShow = false" to="/contact-us"><img src="../assets/img/menu-icons/contact-icon.svg" alt="">Contact Us
                    </router-link>
                </li>
            </ul>

            <div class="action-btn">
                <button @click="logout()" class="btn btn-outline-red btn-full">Logout</button>
            </div>
        </div>
    </div>
    <div v-show="!userState">
        <div class="navbar-header">
            <div class="media">
                <img src="../assets/img/profile-images/profile-not-login-img.svg" class="mr-3" alt="user-img">
                <div class="media-body">
                    <p>Not logged in</p>
                </div>
            </div>
        </div>
        <div class="navbar-body">
            <ul class="main-menu">
                <li>
                    <router-link @click="menuShow = false" to="/"><img src="../assets/img/menu-icons/home-icon.svg" alt="Home"> Home
                    </router-link>
                </li>
                <li>
                    <router-link to="/Contact-us"><img src="../assets/img/menu-icons/contact-icon.svg" alt="">Contact Us
                    </router-link>
                </li>
            </ul>

            <div class="action-btn">
                <router-link to="/login" class="btn btn-purple btn-full">Login</router-link>
                <router-link to="/sign-up" class="btn btn-outline btn-full mt-3">Register</router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import config from '../config'
export default {
    name: 'SidebarNav',
    props: {
        userLogin: Boolean,
        menuShow: Boolean,
    },
    data() {
        return {
            userState: this.userLogin,
            baseImageUrl: ''
        }
    },
    methods: {
        logout() {

            const token = this.$cookie.getCookie('sanctum_token');

            this.axios.post(config.$baseUrl + 'logout',{}, {
                    headers: {
                        'Accept':'application/json',
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {

                    this.cookiesClear();

                }.bind(this));
        },
        cookiesClear()
        {
            this.userState = false

                    this.$cookie.removeCookie('sanctum_token')
                    this.$cookie.removeCookie('user_id')
                    this.$cookie.removeCookie('user_image')
                    this.$cookie.removeCookie('user_name')
                    this.$cookie.removeCookie('user_data')

                    this.$router.push('/login');

                    location.reload();
        }


    },
    mounted() {
        this.baseImageUrl = config.$baseImageUrl
    }
}
</script>

<style scoped lang="scss">

</style>
