<template>
<DefaultLayout>
    <template v-slot:header>
        <Header :type="'back-signUp'" :headerTitle="title" :balanceStatus="''" />
    </template>
    <template v-slot:hero>
        <HeroMain :page="'signUp'"></HeroMain>
    </template>
    <template v-slot:content>
        <section class="wrapper-content">
            <div class="container">
                <form @submit.prevent="register()" ref="regsiterForm">
                    <div v-if="errorMessages.length > 0" class="Messages shadow-sm">
                        <div v-for="(item, key) in errorMessages[0]" :key="key" class="text-danger">
                            <strong>{{ key }} : </strong> {{ item[0] }}
                        </div>
                    </div>
                    <div class="form-group text-center">
                        <div class="profile-image-upload">
                            <div class="user-img">
                                <label for="image">
                                    <img v-if="url.length == ''" src="../assets//img/profile-images/profile-default-img.svg" alt="User-img" />
                                    <img v-else :src="url" />
                                    <br />
                                    Upload Photo<span class="required-field">*</span></label>
                            </div>
                            <input style="display: none" accept="image/png,image/jpeg" id="image" ref="image" class="hidden" type="file" v-on:change="onChangeFileUpload($event)" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="username">Login Username<span class="required-field">*</span></label>
                        <input placeholder="Login Username" autocomplete="off" required v-model="username" class="form-control" type="text" name="username" id="username" />
                        <small class="clr-brand">Display name for ranking, do not use real name or personal info.</small>
                    </div>
                    <div class="form-group">
                        <label for="oxusername">OX88 Username<span class="required-field">*</span></label>
                        <input required v-model="oxusername" class="form-control" type="text" name="oxusername" id="oxusername" placeholder="Your official OX88 username" />
                        <small class="clr-brand">Note: Prize will go into this account & you can't change this
                            afterwards</small>
                    </div>
                    <div class="form-group">
                        <label for="email">Email<span class="required-field">*</span></label>
                        <input placeholder="Enter email" required v-model="email" class="form-control" type="email" name="email" id="email" />
                        <small class="clr-brand">Required for verification</small>
                    </div>
                    <div class="form-group">
                        <label for="password">Password<span class="required-field">*</span></label>
                        <div class="position-relative">
                            <input placeholder="Enter password" required v-model="password" class="form-control" :type="type" name="password" id="password" />
                            <span role="button" @click="eye()" class="eye-icon">
                                <img src="../assets//img/eye-icon.svg" alt="" />
                            </span>
                        </div>
                    </div>
                    <div class="form-group pt-4">
                        <button type="submit" class="btn btn-purple btn-full">
                            Register
                        </button>
                    </div>
                </form>
            </div>
        </section>
    </template>
    <template v-slot:modals>
        <!-- Modal -->
    </template>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
// @ is an alias to /src
import SidebarNav from '@/components/SidebarNav.vue'
import Header from '@/components/Header.vue'
import HeroMain from '@/components/HeroMain.vue'
import config from '../config'

export default {
    name: 'SignUp',
    data() {
        return {
            menuShow: false,
            userLogin: true,
            baseUrl: "",
            url: '',
            type: 'password',
            title: "",
            errorMessages: [],
            file: '',
            username: "",
            oxusername: "",
            email: "",
            password: ""
        }
    },
    components: {
        DefaultLayout,
        SidebarNav,
        HeroMain,
        Header
    },
    mounted() {
        this.baseUrl = config.$baseUrl
    },
    methods: {
        eye() {
            if (this.type == 'password')
                this.type = 'text';
            else if (this.type == 'text')
                this.type = 'password';
        },
        toggleMenu() {
            this.menuShow = true
        },
        onChangeFileUpload(event) {
            this.image = this.$refs.image.files[0];

            if (this.image.size > 2048000)

                this.errorMessages[0] = {
                    "Image": ["The image must not be greater than 2048 kilobytes."]
                };
            else
                this.url = URL.createObjectURL(this.image);
        },

        register() {

            if(this.username.length<6)
            {
                this.errorMessages[0]=({
                    'Username' : ['must be at least 6 characters']
                });
                return;
            }
            if(this.url.length=='')
            {
                this.errorMessages[0]=({
                    'Image' : ['Upload the profile logo']
                });
                return;
            }
            

            let formData = new FormData(this.$refs.regsiterForm);

            formData.append('image', this.image);

            this.axios.post(this.baseUrl + 'register',
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function (data) {
                    if (data.data.status == true) {
                        document.cookie = "message=You have successfully registered please login here";
                        this.$router.push('/');
                    }

                    if (data.data.status == false) {
                        this.errorMessages[0] = data.data.errors;
                    }

                }.bind(this));
        }
    },
}
</script>

<style scoped>
.Messages {
    padding: 10px;
    border: 1px solid red;
}
</style>
