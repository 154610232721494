<template>
    <div class="main">
        <slot name="header"></slot>
        <slot name="hero"></slot>

        <slot name="content"></slot>
    </div>
    <!-- Modal -->
    <slot name="modals"></slot>

</template>

<script>
    import HeroMain from '@/components/HeroMain.vue'
    export default {
        name: 'DefaultLayout',
        data() {
            return {
//                menuShow: false,
//                userLogin: true,
            }
        },
        components: {
            HeroMain,
        },
        mounted() {
        },
        methods: {
//            openMenu (){
//                this.menuShow = true
//            }
        },
    }
</script>
