import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueCookieNext } from 'vue-cookie-next'

// import * as Vue from 'vue' // in Vue 3

import axios from 'axios'
import VueAxios from 'vue-axios'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/main.css'

createApp(App).use(store).use(router).use(VueCookieNext).use(VueAxios, axios).mount('#app')
