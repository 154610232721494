<template>
    <SidebarNav
        :menuShow="menuShow"
        :userLogin="userLogin"
    ></SidebarNav>
    <header class="header" :class="type== 'back' ? 'header-sub':''">
        <div>
            <div v-if="type == 'menu'">
                <div class="nav-btn">
                    <a href="#" @click="sideBarBtn()" class="menu-toggle-btn">
                        <img src="../assets/img/toggle-button-icon.svg" alt="toggle-icon">
                    </a>
                </div>
            </div>
            <div v-if="type == 'back'">
                <div class="nav-btn">
                    <router-link to="/" class="btn-back">
                        <img src="../assets/img/arrow-back-icon.svg">
                    </router-link>
                </div>
            </div>
            <div v-if="type == 'back-signUp'">
                <div class="nav-btn">
                    <router-link to="/login" class="btn-back">
                        <img src="../assets/img/arrow-back-icon.svg">
                    </router-link>
                </div>
            </div>
        </div>
        <div class="header-title">
            <h5 :class="headText=='result history'? 'title-center' : ''" v-if="headerTitle == headText">
                {{headText}}
            </h5>
        </div>
        <div class="balance-status">
            <div v-if="balanceStatus == 'yes'">
                <span><img src="../assets/img/money.svg" alt="money"></span>
                <span class="balance">{{ balance }} <span class="fund-suffix">FUND{{ balance>1?'S':'' }}</span></span>
            </div>
        </div>
    </header>
</template>

<script>
    import SidebarNav from './SidebarNav.vue'
    import config from '../config';
    export default {
        name: 'Header',
        data() {
            return {
                menuShow: false,
                userLogin: true,
                userBalance: '',
                headText: this.headerTitle,
                headContent: this.headerContent,
                bStatus: this.balanceStatus,
            }
        },
        components: {
            SidebarNav,
        },
        props: {
            type: String,
            headerTitle: String,
            balanceStatus: String,
            headerContent: String,
        },
        methods: {
           sideBarBtn(){
                let loggedInUserToken = this.$cookie.getCookie('sanctum_token');
                if(!loggedInUserToken)
                this.$router.push('/login');
                return this.menuShow = !this.menuShow;
           }
        },

        computed:{
            balance(){ 

            const token = this.$cookie.getCookie('sanctum_token');

            if(!token) return; 

            this.axios.get(config.$baseUrl + 'userBalance?user_id='+this.$cookie.getCookie('user_id'), {
                    headers: {
                        'Accept':'application/json',
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {

                 this.$store.commit("setUserCurrentBalance",response.data.userBalance)
                 this.userBalance=response.data.userBalance;

                }.bind(this));
                return this.userBalance;
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
