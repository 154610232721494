<template>
<DefaultLayout>
    <template v-slot:header>
        <Header :type="''" :headerTitle=title :balanceStatus="''" />
    </template>
    <template v-slot:hero>
        <HeroMain :page="'reset-password'"></HeroMain>
    </template>
    <template v-slot:content>
        <section class="wrapper-content">
            <div class="container">
                <div v-if="errors.length>0" class="message">
                    <div class="alert alert-danger">{{ errors }}</div>
                </div>
                <div v-if="laravel_errors.length>0" class="message alert alert-danger">
                    <div v-for="(error,index) in laravel_errors" :key="index">
                        <div>{{ error }}</div>
                    </div>
                </div>
                <form class="form login-form" action="">
                    <div class="form-group">
                        <label for="password">Password</label>
                        <div class="position-relative">
                            <input required v-model="password" class="form-control" :type="type1" name="password" id="password" placeholder="Enter new password">
                            <span role="button" @click="eye(1)" class="eye-icon">
                                <img src="../assets/img/eye-icon.svg" alt="">
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password_confirmation">Confirm Password</label>
                        <div class="position-relative">
                            <input required v-model="password_confirmation" class="form-control" :type="type2" name="password_confirmation" id="password_confirmation" placeholder="Confirm password">
                            <span role="button" @click="eye(2)" class="eye-icon">
                                <img src="../assets/img/eye-icon.svg" alt="">
                            </span>
                        </div>
                    </div>
                    <div class="form-group mt-5">
                        <button type="submit" @click.stop.prevent="submit()" class="btn btn-purple btn-full">Change password</button>
                    </div>
                </form>
            </div>

        </section>
    </template>
    <template v-slot:modals>
        <!-- Modal -->
    </template>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
// @ is an alias to /src
import SidebarNav from '@/components/SidebarNav.vue'
import Header from '@/components/Header.vue'
import HeroMain from '@/components/HeroMain.vue'
import config from '../config'

// google recaptcha
import {
    computed,
    defineComponent
} from 'vue';
import {
    VueRecaptcha
} from 'vue-recaptcha';

export default {
    name: 'Login',
    data() {
        return {
            menuShow: false,
            userLogin: true,
            baseUrl: "",
            errors: '',
            laravel_errors: [],
            type1: 'password',
            type2: 'password',
            title: "",
            password: "",
            password_confirmation: "",
            token: '',
            email: ''
        }
    },
    components: {
        DefaultLayout,
        VueRecaptcha,
        SidebarNav,
        HeroMain,
        Header
    },
    mounted() {
        this.baseUrl = config.$baseUrl;
        this.token = this.$route.query.token;
        this.email = this.$route.query.email;
    },
    methods: {
        eye(type) {
            if (type == 1) {
                if (this.type1 == 'password')
                    this.type1 = 'text';
                else if (this.type1 == 'text')
                    this.type1 = 'password';
            } else if (type == 2) {
                if (this.type2 == 'password')
                    this.type2 = 'text';
                else if (this.type2 == 'text')
                    this.type2 = 'password';
            }

        },
        toggleMenu() {
            this.menuShow = true
        },
        submit() {

            if (this.password != '' && this.password_confirmation != '') {
                this.axios.post(this.baseUrl + 'reset-password', {
                        email: this.email,
                        token: this.token,
                        password: this.password,
                        password_confirmation: this.password_confirmation
                    }).then(function (data) {

                        if (data.data.success == false) {
                            let errors = Object.values(data.data.errors);
                            errors = errors.flat();
                            this.laravel_errors = errors;
                        } else {
                            document.cookie = "message=Password changed successfully";
                            this.$router.push('/');
                        }
                    }.bind(this))
            } else this.errors = "Passwords does not matched";
        }
    },
}
</script>
