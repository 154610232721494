<template>
<DefaultLayout>
    <template v-slot:header>
        <Header :type="'menu'" :headerTitle=title :balanceStatus="'yes'" />
    </template>
    <template v-slot:hero>
        <HeroMain :page="'betNow'" :closeIn="closeIn"></HeroMain>
    </template>
    <template v-slot:content>
        <section class="wrapper-content">
            <div class="container">
                <div class="main-card">
                    <div v-if="errors.length>0" class="errors">
                        <div class="alert alert-danger">{{ errors }}</div>
                    </div>
                    <ul class="matches-schedule">
                        <div v-if="betNowMatches.length>0" class="check">
                            <li v-for="(match,index) in betNowMatches" :key="match.id" class="bet-now-match">
                                <h3 class="text-center text-capitalize">{{ match.name }}</h3>
                                <div class="match-between">
                                    <div class="team">
                                        <div class="team-logo">
                                            <img :src="baseImageUrl+match.teamone.flag" :alt="match.teamone.name">
                                        </div>
                                        <div class="team-name">
                                            <span>{{ match.teamone.name }}</span>
                                        </div>
                                    </div>
                                    <div class="match-time">
                                        <h3>vs</h3>
                                        <span class="time">{{ match.time }}</span>
                                    </div>
                                    <div class="team">
                                        <div class="team-logo">
                                            <img :src="baseImageUrl+match.teamtwo.flag" :alt="match.teamtwo.name">
                                        </div>
                                        <div class="team-name">
                                            <span>{{ match.teamtwo.name }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="bet-now-match-points">
                                    <div class="team">
                                        <div class="team-name">
                                            <span>{{ match.teamone.name }}</span>
                                        </div>
                                        <p class="match-point">{{ match.odd_one }}</p>
                                    </div>
                                    <div class="match-time">
                                        <p class="draw">Draw</p>
                                        <p class="match-point">{{ match.draw }}</p>
                                    </div>
                                    <div class="team">
                                        <div class="team-name">
                                            <span>{{ match.teamtwo.name }}</span>
                                        </div>
                                        <p class="match-point">{{ match.odd_two }}</p>
                                    </div>
                                </div>
                                <div class="pick-option">
                                    <select name="" v-model="match.bet_option">
                                        <option :value="''">Pick Option</option>
                                        <option :value="match.teamone.id">{{ match.teamone.name }}</option>
                                        <option :value="match.teamtwo.id">{{ match.teamtwo.name }}</option>
                                        <option value="draw">Draw</option>
                                        <option value="not bet">No Bet</option>
                                    </select>
                                    <input type="text" v-if="match.bet_option=='not bet'" readonly class="form-control" placeholder="Bet amount...">
                                    <input type="text" v-else-if="match.bet_option==''" readonly class="form-control" placeholder="Bet amount...">
                                    <input type="text" @change="confirmBet(index)" v-else v-model="match.fund_used" class="form-control" placeholder="Bet amount...">
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>

                <button v-if="this.betOnMatches.length>0" data-toggle="modal" data-target="#confirmBetModal" class="btn btn-purple btn-full mt-4">Confirm Bet</button>
                <button v-else @click='selectOption()' class="btn btn-purple btn-full mt-4">Confirm Bet</button>
            </div>

        </section>
    </template>
    <template v-slot:modals>
        <!-- Modal -->
        <div class="modal fade confirm-bet-modal" id="confirmBetModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h1 class="modal-title">Confirm Bet</h1>
                        <p class="m-0">Bet made cannot be changed</p>
                    </div>
                    <div class="modal-body">
                        <ul v-if="check" class="bet-schedule">

                            <div v-if="betOnMatches.length>0" class="check">
                                <li class="" v-for="(match,index) in betOnMatches" :key="index">
                                    <div class="match-title text-capitalize">
                                        <h3>{{ match.name }}</h3>
                                        <span class="time">{{ match.time }}</span>
                                    </div>
                                    <div class="bet-status">
                                        <div class="team">
                                            <div class="team-logo">
                                                <img v-if="match.bet_option==match.team_one" :src="baseImageUrl+match.teamone.flag" :alt="match.teamone.name">
                                                <img v-else-if="match.bet_option==match.team_two" :src="baseImageUrl+match.teamtwo.flag" :alt="match.teamtwo.name">
                                                <div v-else-if="match.bet_option=='draw'" class="draw-match">DRAW</div>
                                            </div>
                                            <div class="team-name">
                                                <span v-if="match.bet_option==match.team_one">{{ match.teamone.name }}</span>
                                                <span v-else-if="match.bet_option==match.team_two">{{ match.teamtwo.name }}</span>
                                            </div>
                                        </div>
                                        <div v-if="match.bet_option!=''" class="match-ratio">
                                            <p class="odds">Odds</p>
                                            <p v-if="match.bet_option==match.team_one" class="ratio">{{ match.odd_one }}</p>
                                            <p v-else-if="match.bet_option==match.team_two" class="ratio">{{ match.odd_two }}</p>
                                            <p v-else-if="match.bet_option=='draw'" class="ratio">{{ match.draw }}</p>
                                        </div>
                                        <div v-if="match.bet_option!=''">
                                            <p class="amount">amount</p>
                                            <p class="funds">{{ match.fund_used }} FUND</p>
                                        </div>

                                        <div v-else class="bet-status not-bet">
                                            <h3 class="not-bet">No Bet</h3>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                        <div v-else class="errors">
                            <h1 class="text-danger text-center">You have used more funds than you have</h1>
                        </div>
                    </div>
                    <div class="p-4">
                        <button v-if="check" data-dismiss="modal" @click="saveBets()" data-toggle="modal" type="button" class="btn btn-purple btn-full">Confirm</button>
                        <button type="button" class="btn btn-outline btn-full mt-2" data-dismiss="modal">Go back</button>

                    </div>
                </div>
            </div>
        </div>
        <div :class="[betMadeModal?'show fade':'']" :style="[betMadeModal?'display:block;':'']" class="modal  bet-made-modal show" v-if="betMadeModal">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <img src="../assets/img/check-icon.svg" alt="Check-icon">
                        <h2>Bet made!</h2>
                        <h5>Good luck</h5>
                    </div>
                    <div class="p-4">
                        <button type="button" class="btn btn-purple btn-full" data-dismiss="modal" @click="backToHome()">Back to Home</button>
                    </div>
                </div>
            </div>
        </div>
    </template>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
// @ is an alias to /src
import SidebarNav from '@/components/SidebarNav.vue'
import HeroMain from '@/components/HeroMain.vue'
import Header from '@/components/Header.vue'
import config from '../config'
export default {
    name: 'BetNow',
    data() {
        return {
            menuShow: false,
            userLogin: true,
            sum: "",
            title: "",
            errors:'',
            closeIn: '',
            userFund: '',
            baseImageUrl: '',
            betNowMatches: [],
            betOnMatches: [],
            betMadeModal: false,
        }
    },
    components: {
        DefaultLayout,
        SidebarNav,
        HeroMain,
        Header
    },
    computed:{
        check()
        {
            var sum=0;
            this.betOnMatches.forEach((value,key)=>{
                sum=sum+parseInt(value.fund_used);
            });

            return sum>this.userFund?false:true;
        }
    },
    mounted() {
        this.betMatches();
        this.baseImageUrl = config.$baseImageUrl;
    },
    methods: {
        confirmBet(index) {
            if (this.betNowMatches[index].fund_used >= 50 && this.betNowMatches[index].bet_option!='not bet') {
                var exists = false;
                var key1;

                this.betOnMatches.forEach((value, key) => {
                    if (value.id == this.betNowMatches[index].id) {
                        exists = true;
                        key1 = key;
                    }
                });

                if (exists)
                {
                    this.betOnMatches[key1] = this.betNowMatches[index];
                }
                else if(exists==false)
                {
                    this.betOnMatches.push(this.betNowMatches[index]);
                } 

            } else
                this.errors="Bet Amount can't be less than 50";
        },
        saveBets() {
            const token = this.$cookie.getCookie('sanctum_token');
            this.axios.post(config.$baseUrl + 'saveBets',
                    this.betOnMatches, {
                        headers: {
                            'Accept': 'application/json',
                            "Authorization": `Bearer ${token}`
                        }
                    }).then(function (response) {
                        if(response.data.status)
                        {
                            this.betMadeModal = true;
                        }
                        else if(response.data.status==false)
                        {
                            this.errors = response.data.message;
                        }

                }.bind(this));

        },
        backToHome() {
            this.$router.push('/');
        },
        toggleMenu() {
            this.menuShow = true
        },
        betMatches() {
            const token = this.$cookie.getCookie('sanctum_token');
            this.axios.get(config.$baseUrl + 'betNowMatches', {
                    headers: {
                        'Accept': 'application/json',
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {

                    response.data.matches.forEach((value, index) => {
                        this.betNowMatches.push({...value,'bet_option':""})
                    });
                    this.userFund=response.data.userFund;

                    if (this.betNowMatches.length > 0) {

                        var t1 = new Date(new Date(this.betNowMatches[0].date + " 00:00:00"));
                        
                        var t2 = new Date(new Date());
                        var value = (t1.getTime() - t2.getTime()) / 1000;

                        const sec = parseInt(value, 10); // convert value to number if it's string
                        let hours = Math.floor(sec / 3600); // get hours
                        let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
                        let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
                        // add 0 if value < 10; Example: 2 => 02
                        if (hours < 10) {
                            hours = "0" + hours;
                        }
                        if (minutes < 10) {
                            minutes = "0" + minutes;
                        }
                        if (seconds < 10) {
                            seconds = "0" + seconds;
                        }
                        //                return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS

                        this.closeIn = hours + " H " + minutes + " " + " MIN";

                    }

                }.bind(this));
        }
        ,
        selectOption()
        {
            alert("please select at least one option");
        }
    },
}
</script>
