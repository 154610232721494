<template>
<DefaultLayout>
    <template v-slot:header>
        <Header :type="'menu'" :headerTitle=title :balanceStatus="'yes'" />
    </template>
    <template v-slot:hero>
        <HeroMain :page="'home'" :date="nextDate"></HeroMain>
    </template>
    <template v-slot:content>
        <section class="wrapper-content">
            <div class="container">
                <div v-if="loaderActive" class="main-card">
                    <loader :active="loaderActive" />
                </div>
                <div v-else class="main-card">
                    <ul class="matches-schedule">
                        <li v-for="nextMatch in nextMatches" :key="nextMatch.id">
                            <div class="match-between">
                                <div class="team">
                                    <div class="team-logo">
                                        <img :src="baseImageUrl+nextMatch.teamone.flag" :alt="nextMatch.teamone.name">
                                    </div>
                                    <div class="team-name">
                                        <span>{{ nextMatch.teamone.name }}</span>
                                    </div>
                                </div>
                                <div class="match-time">
                                    <h3>vs</h3>
                                    <span class="time">{{ nextMatch.time }}</span>
                                </div>
                                <div class="team">
                                    <div class="team-logo">
                                        <img :src="baseImageUrl+nextMatch.teamtwo.flag" :alt="nextMatch.teamtwo.name">
                                    </div>
                                    <div class="team-name">
                                        <span>{{ nextMatch.teamtwo.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </li>

                    </ul>
                    <div v-if="counterShow" class="prediction-start">
                        <p>Prediction close in</p>
                        <div class="prediction-time">
                            <div>
                                <h4> {{countDownTime.hour}}<span>h</span></h4>
                            </div>
                            <div>
                                <h4>{{countDownTime.minutes}}<span>m</span></h4>
                            </div>
                            <div>
                                <h4>{{countDownTime.seconds}}<span>s</span></h4>
                            </div>

                        </div>
                    </div>
                    <router-link v-if="nextMatches.length>0" class="btn btn-purple btn-full mt-4" to="/bet-now">Bet Now</router-link>
                    <div v-else class="no-current-match">
                        <button class="btn btn-purple btn-full mt-4">There is no current match</button>
                    </div>
                    <button @click="goToResultHistory()" class="btn btn-primary btn-full mt-4">Result History</button>
                </div>

                <div v-if="leaderBoard.length>0" class="main-card normal home mt-5">
                    <div class="leaderboard-top-header">
                        <div class="text">
                            <h5 class="text-center py-4 m-0">
                                <img class="mr-2" src="../assets/img/ranking-icon-black.svg" alt="Ranking Icon" />Leaderboard
                            </h5>
                        </div>
                        <div class="reward-btn">
                            <button data-toggle="modal" data-target="#rankingRewardModal" class="btn btn-red btn-sm">
                                Rewards
                            </button>
                        </div>
                    </div>
                    <table class="table table-striped leaderboard-table m-0">
                        <thead>
                            <tr>
                                <th scope="col">Rank</th>
                                <th scope="col">User</th>
                                <th scope="col">Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="rankState in leaderBoard" :key="rankState.id">
                                <td>#{{rankState.position}}</td>
                                <td>{{rankState.name}}</td>
                                <td>{{rankState.rank}}</td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <router-link to="/ranking-and-reward" class="btn btn-outline btn-full">View All <i class="fa fa-arrow-right ml-3"></i></router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </section>
    </template>
    <template v-slot:modals>
        <!-- Modal -->
        <!--<button id="openWelcomeModal" data-toggle="modal" data-target="#welcomeBackModal" class="" hidden></button>-->
        <div class="modal fade welcome-back-modal" id="welcomeBackModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header align-items-center">
                        <h1 class="modal-title">Welcome Back!</h1>
                    </div>
                    <div class="modal-body">
                        <div class="inner-wrapper">
                            <div>
                                <p>You got</p>
                                <h2>50 FUNDS</h2>
                            </div>
                            <div>
                                <img src="../assets/img/money-with-wings.svg" alt="Money">
                            </div>
                        </div>
                    </div>
                    <div class="p-4">
                        <button data-dismiss="modal" type="button" class="btn btn-purple btn-full">Tap to continue</button>
                        <p class="note mt-3">Earn <b>100 FUND</b> on total <b>7 Logins</b></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade ranking-reward-modal" id="rankingRewardModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header rm-rewards-header justify-content-center border-0">
                        <h1 class="modal-title">Ranking Reward</h1>
                    </div>
                    <div class="modal-body">
                        <table class="table table-striped leaderboard-table m-0">
                            <tbody>
                                <tr v-for="reward in rankingRewards" :key="reward.id">
                                    <td>
                                        <div>
                                            {{ reward.rank }} <br>
                                            <small v-if="reward.description">{{ reward.description }}</small>
                                        </div>

                                    </td>
                                    <td>
                                        <button class="btn btn-full" :class="reward.rm >= 1000 ? 'btn-warning':'' ,
                                             reward.rm < 1000 && reward.rm >= 500 ? 'btn-primary':'' ,
                                            reward.rm < 500 && reward.rm >= 250 ? 'btn-success':'' ,
                                             reward.rm < 250 && reward.rm >= 100 ? 'btn-danger':''">
                                            RM {{reward.rm}}
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div class="p-4">
                        <button data-dismiss="modal" @click="viewRanking()" class="btn btn-purple view-ranking-btn btn-full mt-4">View Ranking</button>
                        <button type="button" class="btn btn-purple btn-full mt-2" data-dismiss="modal">Close Tab</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade ranking-reward-modal" id="how-to-bet-modal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header rm-rewards-header justify-content-center border-0">
                        <h1 class="modal-title text-primary">How to bet</h1>
                    </div>
                    <div class="modal-body">
                        <div class="how-to-bet-video">
                            <video autoplay="autoplay" controlsList="nodownload" controls src="../assets/how-to-bet.mp4"></video>
                        </div>
                    </div>
                    <div class="p-4">
                        <button type="button" class="btn btn-purple btn-full mt-2" data-dismiss="modal">Close Tab</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade get-funds-modal" id="getFundsModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header align-items-center">
                        <h1 class="modal-title">To Earn Funds</h1>
                    </div>
                    <div class="modal-body">
                        <div class="inner-wrapper">
                            <ul class="main-list">
                                <li>
                                    <span>1.</span>
                                    <div>Login daily (50 Funds)</div>
                                </li>
                                <li>
                                    <span>2.</span>
                                    <div>
                                        Prediction Time Milestone
                                        <ul class="sub-list">
                                            <li><span>-</span>3 Times (10 Funds)</li>
                                            <li><span>-</span>5 Times (20 Funds)</li>
                                            <li><span>-</span>10 Times (50 Funds)</li>
                                            <li><span>-</span>20 Times (100 Funds)</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <span>3.</span>
                                    <div>
                                        Make deposit in OX88
                                        <ul class="sub-list">
                                            <li><span>-</span>Each RM10 deposit = 1 Fund (capped on 100 funds, RM1000)</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="px-4 pb-4">
                        <button data-dismiss="modal" type="button" class="btn btn-purple btn-full">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </template>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
// @ is an alias to /src
import SidebarNav from '@/components/SidebarNav.vue'
import Header from '@/components/Header.vue'
import HeroMain from '@/components/HeroMain.vue'
import config from '../config'
import Loader from '../components/Loader.vue'

export default {
    name: 'Home',
    data() {
        return {
            countDownTime: 0,
            timeoutTime: 0,
            counterShow: true,
            menuShow: false,
            loaderActive: true,
            userLogin: true,
            title: "",
            baseImageUrl: "",
            leaderBoard: [],
            rankingRewards: [],
            nextMatches: [],
            ddate: '',
            timer: false,
            loggedInUserToken:''
        }
    },
    components: {
        DefaultLayout,
        SidebarNav,
        HeroMain,
        Header,
        Loader
    },
    mounted() {

        this.loggedInUserToken = this.$cookie.getCookie('sanctum_token');

        this.getEvents();

        setInterval(() => {
            this.getEvents();
        }, 10000)

        this.getLeaderBoardData();

        this.getRewardData();

    },
    computed: {
        nextDate() {
            if (this.nextMatches.length > 0) {
                return new Date(this.nextMatches[0].date).getDate() + " " + new Date(this.nextMatches[0].date).toLocaleString('en-us', {
                    month: 'long'
                });
            }
        }

    },
    methods: {
        getLeaderBoardData() {
            
            var self = this;

            if(!this.loggedInUserToken)
            {
                self.loaderActive = false;
                return;
            }

            this.axios.get(config.$baseUrl + 'leaderboard?record=3', {
                    headers: {
                        "Authorization": `Bearer ${this.loggedInUserToken}`
                    }
                }).then(function (response) {

                    self.leaderBoard = response.data;

                });
                
                self.loaderActive = false;
        },
        getEvents() {

            var self = this;
            let headers = {};

            self.baseImageUrl = config.$baseImageUrl

            if(this.loggedInUserToken)
            headers = { "Authorization": `Bearer ${this.loggedInUserToken}` };

            this.axios.get(config.$baseUrl + 'get-matches', {
                    headers: headers
                }).then(function (response) {

                    self.nextMatches = response.data;

                    if (response.data.length > 0) {

                        var t1 = new Date(new Date(self.nextMatches[0].date + " 00:00:00"));
                        
                        var t2 = new Date(new Date());
                        var dif = (t1.getTime() - t2.getTime()) / 1000;
                        self.countDownTime = dif;
                        self.timeoutTime = dif;
                        if (self.timer) {
                            clearInterval(self.timer);
                            self.updateTimeout()
                        }
                        self.timer = setInterval(() => self.updateTimeout(), 1000);
                    } else self.counterShow = false;

                })
                .catch(function (error) {
                    if (error.response.data.message == 'Unauthenticated.') {
                        this.$cookie.removeCookie('sanctum_token')
                        this.$cookie.removeCookie('user_id')
                        this.$cookie.removeCookie('user_image')
                        this.$cookie.removeCookie('user_name')
                        this.$cookie.removeCookie('user_data')

                        location.reload();
                    }
                }.bind(this));
                self.loaderActive = false;
        },
        viewRanking() {
            this.$router.push('ranking-and-reward');

        },
        goToResultHistory() {
            this.$router.push('result-history');

        },
        toggleMenu() {
            this.menuShow = true
        },

        convertToTime(value) {
            const sec = parseInt(value, 10); // convert value to number if it's string
            let hours = Math.floor(sec / 3600); // get hours
            let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
            let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
            // add 0 if value < 10; Example: 2 => 02
            if (hours < 10) {
                hours = "0" + hours;
            }
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (seconds < 10) {
                seconds = "0" + seconds;
            }
            //                return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
            var obj = {
                hour: hours,
                minutes: minutes,
                seconds: seconds,
            }
            return obj
        },
        updateTimeout() {
            if (this.timeoutTime > 0) {
                this.timeoutTime = this.timeoutTime - 1;
                this.countDownTime = this.convertToTime(this.timeoutTime);
            } else if (this.timeoutTime == 0) {
                this.countDownTime = 'SESSION EXPIRED';
                this.counterShow = false;
            }

        },
        getRewardData() {
            
            if(!this.loggedInUserToken)
            return;

            this.axios.get(config.$baseUrl + 'get-reward-data', {
                    headers: {
                        "Authorization": `Bearer ${this.loggedInUserToken}`
                    }
                }).then(function (response) {

                    this.rankingRewards = response.data;

                }.bind(this));

        }
    },

}
</script>
