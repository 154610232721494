<template>
<DefaultLayout>
    <template v-slot:header>
        <Header :type="'menu'" :headerTitle=title :balanceStatus="'yes'" />
    </template>
    <template v-slot:hero>
        <HeroMain :page="'myWallet'"></HeroMain>
    </template>
    <template v-slot:content>
        <section class="wrapper-content">
            <div class="container">
                <div v-if="loaderActive" class="main-card">
                    <loader :active="loaderActive" />
                </div>
                <div v-else class="main-card">
                    <button data-toggle="modal" data-target="#convertToFundModal" class="btn btn-red btn-full">Convert to fund</button>
                    <!-- <ul class="wallet-result">
                        <li v-for="(bet,index) in betData" :key="index" class="result">
                            <div>
                                <p>{{ bet.event.name }}, {{ bet.event.time }} {{ bet.event.winner?bet.event.winner.name:'Draw' }} <br><span>(odds {{ getOdds(bet) }})</span></p>
                                <p class="date">{{ bet.event.date }}</p>
                            </div>
                            <div>
                                <p :class=" bet.status=='won'?'success':'loss'  ">{{ bet.status=='won'? "+"+bet.transaction.credit+" FP":"Loss" }} </p>
                            </div>
                        </li>
                    </ul> -->
                    <ul class="nav nav-pills mb-3 mt-3" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="pills-summary-tab" data-toggle="pill" href="#pills-summary" role="tab" aria-controls="pills-summary" aria-selected="true">Summary</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-fp-history-tab" data-toggle="pill" href="#pills-fp-history" role="tab" aria-controls="pills-fp-history" aria-selected="false">Fifa Points</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-fund-history-tab" data-toggle="pill" href="#pills-fund-history" role="tab" aria-controls="pills-fund-history" aria-selected="false">Funds</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" id="pills-rm-history-tab" data-toggle="pill" href="#pills-rm-history" role="tab" aria-controls="pills-rm-history" aria-selected="false">RM</a>
                        </li> -->
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-summary" role="tabpanel" aria-labelledby="pills-summary-tab">
                            <table v-if="summary[0].total" class="table w-100 wallet-table">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Gained</th>
                                        <th>Used</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in summary" :key="item.id">
                                        <td>{{ item.type }}</td>
                                        <td>{{ item.gained }}</td>
                                        <td>{{ item.used }}</td>
                                        <td>{{ item.total }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-else class="no-data">
                                <h4>There is no data in your wallet</h4>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-fp-history" role="tabpanel" aria-labelledby="pills-fp-history-tab">
                            <table v-if="fifa_points.length>0" class="table w-100 wallet-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Remarks</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in fifa_points" :key="item.id">
                                        <td>{{moment(item.created_at).format("YYYY-MM-DD")}}</td>
                                        <td>{{ item.remarks }}</td>
                                        <td v-if="item.credit" class="text-success">+{{ item.credit }}</td>
                                        <td v-if="item.debit" class="text-danger">-{{ item.debit }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-else class="no-data">
                                <h4>There is no fifa points in your wallet</h4>
                            </div>
                            <div v-if="loadOrNotFifa==true && fifa_points.length>0" class="text-center"><button @click="fetchWalletData(page=page+1)" class="btn btn-load-more">Load more...</button></div>
                        </div>
                        <div class="tab-pane fade" id="pills-fund-history" role="tabpanel" aria-labelledby="pills-fund-history-tab">
                            <table v-if="funds.length>0" class="table w-100 table-responsive wallet-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Remarks</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in funds" :key="item.id">
                                        <td>{{moment(item.created_at).format("YYYY-MM-DD")}}</td>
                                        <td>{{ item.remarks }}</td>
                                        <td v-if="item.credit" class="text-success">+{{ item.credit }}</td>
                                        <td v-if="item.debit" class="text-danger">-{{ item.debit }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-else class="no-data">
                                <h4>There is no funds in your wallet</h4>
                            </div>
                            <div v-if="loadOrNotFund && funds.length>0" class="text-center"><button @click="fetchWalletData(page=page+1)" class="btn btn-load-more">Load more...</button></div>
                        </div>
                        <!-- <div class="tab-pane fade" id="pills-rm-history" role="tabpanel" aria-labelledby="pills-rm-history-tab">
                            rms history tab
                        </div> -->
                    </div>
                </div>
            </div>
        </section>
    </template>
    <template v-slot:modals>
        <!-- Modal -->
        <div class="modal fade what-is-fp-modal modal-bg-img-sm" id="whatIsFP" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header align-items-center">
                        <h1 class="modal-title">What is FP?</h1>
                    </div>
                    <div class="modal-body">
                        <div class="inner-wrapper">
                            <p>FP is "Fifa Point" which you get
                                from winning bet using fund.
                                Your FP amount will determine
                                your ranking to compete
                                with other players on who has
                                the highest FP.
                            </p>
                            <p>
                                You can convert FP into Funds to
                                try win a bigger amount when
                                needed.
                            </p>
                        </div>
                    </div>
                    <div class="px-4 pb-4">
                        <button data-dismiss="modal" type="button" class="btn btn-purple btn-full">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade convert-to-fund-modal" id="convertToFundModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <h6>Current Balance</h6>
                        <h1>{{ userCurrentFifaPoints }}<span>FP</span></h1>
                        <p>10 FP = 1 Fund</p>
                        <div v-if="errors.length>0" class="errors">
                            <div class="alert alert-danger">{{ errors }}</div>
                        </div>
                        <form class="form" action="">
                            <div class="form-group m-0">
                                <input @keyup="increaseFund()" v-model="formData.points" class="form-control" type="text" placeholder="Min. 500FP">
                            </div>
                            <div class="form-group m-0">
                                <span><i class="fa fa-angle-down"></i></span>
                            </div>
                            <div class="form-group">
                                <input readonly :value="formData.fund" class="form-control convert-fund-field" type="text" placeholder="100 Fund">
                                <p class="mt-2">Conversion cannot be reversed</p>
                            </div>

                            <div class="form-group pt-4">
                                <button @click="convertpoints()" data-dismiss="modal" data-target="#conversionDoneModal" type="button" class="btn btn-purple btn-full">Convert</button>
                                <button type="button" class="btn btn-outline btn-full mt-2" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div :class="modalClasses" class="fade conversion-done-modal" id="conversionDoneModal" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header justify-content-center border-0">
                        <h1 class="modal-title m-0">Conversion Done!</h1>
                    </div>
                    <div class="modal-body text-center">
                        <img src="../assets/img/check-icon.svg" alt="Check-icon">
                        <h6 class="mt-5">You now have</h6>
                        <h5>{{ recentfunds }} FUNDS</h5>
                    </div>
                    <div class="px-4 pb-4 pt-2">
                        <button type="button" class="btn btn-purple btn-full mt-2" @click="done()" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </template>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'

import SidebarNav from '@/components/SidebarNav.vue'
import Header from '@/components/Header.vue'
import HeroMain from '@/components/HeroMain.vue'
import Loader from '../components/Loader.vue'
import config from '../config'
import moment from 'moment'
import {
    mapState
} from 'vuex'
export default {
    name: 'MyWallet',
    data() {
        return {
            loaderActive: true,
            menuShow: false,
            userLogin: true,
            title: "",
            errors: '',
            userCurrentFifaPoints: '',
            recentfunds: 0,
            formData: {
                points: '',
                fund: ''
            },
            betData: [],
            summary: [],
            fifa_points: [],
            funds: [],
            rms: [],
            page: 1,
            fifa_points_last_page: '',
            funds_last_page: '',
            loadOrNotFifa: true,
            loadOrNotFund: true,
            moment: moment,
            modalClasses: ['modal', 'fade']
        }
    },
    components: {
        DefaultLayout,
        SidebarNav,
        HeroMain,
        Header,
        Loader
    },
    mounted() {
        this.fetchBetData();
        this.fetchWalletData(this.page);
    },
    methods: {
        done() {
            location.reload();
        },
        fetchWalletData(page) {
            const token = this.$cookie.getCookie('sanctum_token');

            this.axios.get(config.$baseUrl + 'user-wallet-data/' + this.$cookie.getCookie('user_id') + "?page=" + page, {
                    headers: {
                        'Accept': 'application/json',
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {

                    this.loaderActive = false;
                    this.summary = response.data.summary;
                    if (this.fifa_points.length > 0) {
                        if (page == this.fifa_points_last_page) {
                            this.loadOrNotFifa = false;
                        }
                        this.fifa_points = [...this.fifa_points, ...response.data.fifa_points.data];
                    } else {
                        this.fifa_points_last_page = response.data.fifa_points.last_page;
                        this.fifa_points = response.data.fifa_points.data;
                    }
                    if (this.funds.length > 0) {
                        if (page == this.fifa_points_last_page) {
                            this.loadOrNotFund = false;
                        }
                        this.funds = [...this.funds, ...response.data.funds.data];
                    } else {
                        this.funds_last_page = response.data.funds.last_page;
                        this.funds = response.data.funds.data;
                    }
                    this.userCurrentFifaPoints = response.data.userCurrentFifaPoints;

                }.bind(this))

        },
        increaseFund() {
            this.formData.fund = (this.formData.points / 10);
        },
        toggleMenu() {
            this.menuShow = true
        },
        convertpoints() {
            if (this.formData.points == '')
                this.errors = "Please enter the points to convert";
            else if (this.formData.points < 500)
                this.errors = "Minimum points must be 500";
            else if (this.formData.points > this.userCurrentFifaPoints)
                this.errors = "You dont have the required points in your account";
            else if (this.formData.fund < 1)
                this.errors = "Fund can not be less than 1";
            else {

                const token = this.$cookie.getCookie('sanctum_token');

                this.formData.fund = (this.formData.points / 10);

                this.axios.post(config.$baseUrl + 'covert-points', this.formData, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    }).then(function (response) {

                        // alert(JSON.stringify(response.data));
                        if (response.data.status == true) {
                            this.recentfunds = response.data.funds;
                            // this.$router.push('/');
                        }
                        this.formData = {
                            points: '',
                            fund: ''
                        }

                        this.fetchWalletData(this.page);

                        document.body.className += ' modal-open'
                        let modalClasses = this.modalClasses

                        if (modalClasses.indexOf('d-block') > -1) {
                            modalClasses.pop()
                            modalClasses.pop()

                            //hide backdrop
                            let backdrop = document.querySelector('.modal-backdrop')
                            document.body.removeChild(backdrop)
                        } else {
                            modalClasses.push('d-block')
                            modalClasses.push('show')

                            //show backdrop
                            let backdrop = document.createElement('div')
                            backdrop.classList = "modal-backdrop fade show"
                            document.body.appendChild(backdrop)
                        }

                    }.bind(this))
            }
        },

        fetchBetData() {
            const token = this.$cookie.getCookie('sanctum_token');

            this.axios.get(config.$baseUrl + 'get-bets-data', {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {
                    // alert(JSON.stringify(response.data));
                    this.betData = response.data;

                }.bind(this))
        },

        getOdds(bet) {
            if (bet.bet_option == bet.event.team_one)
                return bet.event.odd_one;
            else if (bet.bet_option == bet.event.team_two)
                return bet.event.odd_two;
            else if (bet.bet_option == 'draw')
                return bet.event.draw;
        }
    },
    computed: {
        ...mapState({
            userCurrentBalance: (state) => state.userCurrentBalance
        })
    }
}
</script>
