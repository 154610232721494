<template>
<DefaultLayout>
    <template v-slot:header>
        <Header :type="'menu'" :headerTitle=title :balanceStatus="''" />
    </template>
    <template v-slot:hero>
        <HeroMain :page="'myAccount'"></HeroMain>
    </template>
    <template v-slot:content>
        <section class="wrapper-content">
            <div class="container">
                <div class="main-card password-change-content">
                    <form class="form" @submit.prevent>
                        <router-link to="/my-wallet" class="btn btn-red btn-full">My Wallet</router-link>
                        <router-link to="/ranking-and-reward" class="btn btn-purple btn-full mt-2">Check Ranking</router-link>
                        <div class="form-group text-center mt-4">
                            <label>Change Password</label>
                            <div v-if="errors" class="error-div">{{ errors }}</div>
                        </div>
                        <div class="form-group">
                            <div class="position-relative">
                                <input class="form-control" v-model="formData.password" type="password" placeholder="Password">
                                <!--<span class="eye-icon">-->
                                <!--<img src="../assets/img/eye-icon.svg" alt="">-->
                                <!--</span>-->
                            </div>

                        </div>
                        <div class="form-group">
                            <div class="position-relative">
                                <input class="form-control" v-model="formData.password_confirmation" type="password" placeholder="Confirm Password">
                                <!--<span class="eye-icon">-->
                                <!--<img src="../assets/img/eye-icon.svg" alt="">-->
                                <!--</span>-->
                            </div>

                        </div>
                        <div class="form-group pt-4">
                            <button class="btn btn-purple btn-full" @click="changePassword">Change Password</button>
                        </div>
                    </form>
                </div>
            </div>

        </section>
    </template>
    <template v-slot:modals>
        <!-- Modal -->
    </template>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
// @ is an alias to /src
import SidebarNav from '@/components/SidebarNav.vue'
import Header from '@/components/Header.vue'
import HeroMain from '@/components/HeroMain.vue'
import config from '../config'
export default {
    name: 'MyAccount',
    data() {
        return {
            menuShow: false,
            userLogin: true,
            title: "My Profile",
            $baseUrl:'',
            errors:'',
            formData: {
                user_id:'',
                password: '',
                password_confirmation: ''

            }
        }
    },
    components: {
        DefaultLayout,
        SidebarNav,
        HeroMain,
        Header
    },
    mounted() {
        this.baseUrl=config.$baseUrl;
        this.formData.user_id=this.$cookie.getCookie('user_id');
    },
    methods: {
        toggleMenu() {
            this.menuShow = true
        },
        changePassword() {

            if (this.formData.password.length < 3)
                this.errors="Password must be atleast three character long";
            else if (this.formData.password_confirmation.length < 3)
                this.errors="Please confirm your password";
            else if (this.formData.password != this.formData.password_confirmation)
                this.errors="Password does not matched";
            else {

                const token = this.$cookie.getCookie('sanctum_token');

                this.axios.post(config.$baseUrl + 'change-password',this.formData, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    }).then(function (response) {
                        this.$router.push('/');
                    }.bind(this));
            }
        }
    },
}
</script>
