import { createStore } from 'vuex'

export default createStore({
  state: {
    userCurrentBalance:0,
  },
  mutations: {
    setUserCurrentBalance(state,balance){
      state.userCurrentBalance=balance
    }
  },
  actions: {
  },
  modules: {
  }
})
