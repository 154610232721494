<template>
<div v-if="page == 'home'">
    <section class="hero-main">
        <div class="overlay-purple"></div>
        <div class="container">
            <div class="header-content text-center">
                <div class="reward-btn-group d-flex justify-content-center align-items-center mt-2 mb-3">
                    <button data-toggle="modal" data-target="#how-to-bet-modal" class="btn btn-primary btn-sm">How to bet</button>
                    <button data-toggle="modal" data-target="#getFundsModal" class="btn btn-outline-grey btn-sm">
                        How to get Funds
                    </button>
                </div>
                <h6 ><span v-if="date">Next Match</span></h6>
                <h1>{{ date }}</h1>
            </div>
        </div>
    </section>
</div>
<div v-if="page == 'myWallet'">
    <section class="my-wallet-hero banner-big">
        <div class="container">
            <div class="header-content text-center">
                <div class="reward-btn-group d-flex justify-content-center align-items-center mt-2 mb-3">
                    <button data-toggle="modal" data-target="#whatIsFP" class="btn btn-outline-grey btn-sm">What is FP?</button>
                </div>
                <h6>FP Balance</h6>
                <h1>{{ userFifaPoints }} <span>FP</span></h1>
            </div>
        </div>
    </section>
</div>
<div v-if="page == 'betNow'">
    <section class="hero-main">
        <div class="overlay-purple"></div>
        <div class="container">
            <div class="header-content text-center">
                <div class="reward-btn-group d-flex justify-content-center align-items-center mt-2 mb-3">
                </div>
                <h6>Bet close in</h6>
                <h1>{{ closeIn }}</h1>
            </div>
        </div>
    </section>
</div>
<div v-if="page == 'rankingAndReward'">
    <section class="hero-main">
        <div class="overlay-purple"></div>
        <div class="container">
            <div class="header-content text-center">
                <h6>Your Rank</h6>
                <h1>{{ myRank }}</h1>
                <div class="reward-btn-group d-flex justify-content-center align-items-center mt-2 mb-3">
                    <button data-toggle="modal" data-target="#rankingRewardModal" class="btn btn-red btn-sm">Ranking Rewards</button>
                </div>
            </div>
        </div>
    </section>
</div>
<div v-if="page == 'myAccount'">
    <section class="password-change-hero banner-big">
        <div class="container">
            <div class="header-content text-center">
                <div class="user-bio">
                    <div class="profile-img">
                        <div v-if="this.$cookie.getCookie('user_image')">
                            <img :src="baseImageUrl+this.$cookie.getCookie('user_image')" alt="user-img">
                        </div>
                        <div v-else>
                            <img src="../assets/img/profile-images/profile-img.svg" alt="user-img">
                        </div>
                    </div>
                    <div class="user-name">{{ this.$cookie.getCookie('name') }}</div>
                    <div class="user-email">{{ this.$cookie.getCookie('user_data').email }}</div>
                    <div class="user-id">UserID: {{ this.$cookie.getCookie('user_id') }}</div>
                </div>
            </div>
        </div>
    </section>
</div>

<div v-if="page == 'resultHistory'">
    <section class="result-history-hero banner-small">
        <div class="container">

        </div>
    </section>
</div>
<div v-if="page == 'login'">
    <section class="hero-section login-hero banner-big">
        <div class="container">
            <h2>Welcome!</h2>
            <h1>Sign in</h1>
        </div>
    </section>
</div>
<div v-if="page == 'forgot-password'">
    <section class="hero-section login-hero banner-big">
        <div class="container">
            <h2>Welcome!</h2>
            <h2>Send reset password link</h2>
        </div>
    </section>
</div>
<div v-if="page == 'reset-password'">
    <section class="hero-section login-hero banner-big">
        <div class="container">
            <h2>Welcome!</h2>
            <h2>Change your password</h2>
        </div>
    </section>
</div>
<div v-if="page == 'signUp'">
    <section class="hero-section signUp-hero banner-small">
        <div class="container"></div>
    </section>
</div>
<div v-if="page == 'contactUs'">
    <section class="hero-main">
        <div class="overlay-purple"></div>
        <div class="container">
            <div class="header-content text-center">
                <h1 class="mt-5">Contact Us</h1>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import config from '../config'
import {
    mapState
} from 'vuex';
export default {
    data() {
        return {
            //                menuShow: false,
            //                userLogin: true,
            baseImageUrl: '',
            userFifaPoints:''
        }
    },
    props: {
        page: String,
        date: String,
        myRank: "",
        closeIn: ''
    },
    methods: {
        //            openMenu(){
        //                this.$emit('open-menu');
        //            }
        userFPbalance() {
            const token = this.$cookie.getCookie('sanctum_token');
            if(!token)
            return;

            this.axios.get(config.$baseUrl + 'user-fp-balance/' + this.$cookie.getCookie('user_id'), {
                    headers: {
                        'Accept': 'application/json',
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {
                    this.userFifaPoints=response.data.fifa_points;

                }.bind(this));

        },
    },
    mounted() {
        this.baseImageUrl = config.$baseImageUrl;
        if(this.page!='login')
        {
        if(this.page!='signUp')
        this.userFPbalance();
        }
    },
    computed: {
        ...mapState({
            userCurrentBalance: (state) => state.userCurrentBalance
        })
    }
}
</script>

<style scoped lang="scss">

</style>
