<template>
<DefaultLayout>
    <template v-slot:header>
        <Header :type="''" :headerTitle=title :balanceStatus="''" />
    </template>
    <template v-slot:hero>
        <HeroMain :page="'forgot-password'"></HeroMain>
    </template>
    <template v-slot:content>
        <section class="wrapper-content">
            <div class="container">
                <div v-if="errors.length>0" class="message">
                    <div class="alert alert-danger">{{ errors }}</div>
                </div>
                <div v-if="success.length>0" class="message">
                    <div class="alert alert-success">{{ success }}</div>
                </div>
                <form class="form login-form" action="">
                    <div class="form-group mb-5">
                        <label for="userEmail">Enter email</label>
                        <input required v-model="email" class="form-control" type="text" name="userEmail" id="userEmail" placeholder="email">
                    </div>
                    <div class="form-group mt-5">
                        <button type="submit" @click.stop.prevent="submit()" class="btn btn-purple btn-full">Send Reset Link</button>
                        <router-link to="/login" class="btn btn-outline btn-full mt-2">Login</router-link>
                    </div>
                </form>
            </div>

        </section>
    </template>
    <template v-slot:modals>
        <!-- Modal -->
    </template>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
// @ is an alias to /src
import SidebarNav from '@/components/SidebarNav.vue'
import Header from '@/components/Header.vue'
import HeroMain from '@/components/HeroMain.vue'
import config from '../config'

// google recaptcha
import {
    computed,
    defineComponent
} from 'vue';

export default {
    name: 'ForgotPassword',
    data() {
        return {
            menuShow: false,
            userLogin: true,
            baseUrl: "",
            errors: '',
            success: '',
            title: "",
            email: "",
        }
    },
    components: {
        DefaultLayout,
        SidebarNav,
        HeroMain,
        Header
    },
    mounted() {
        this.baseUrl = config.$baseUrl
    },
    methods: {
        toggleMenu() {
            this.menuShow = true
        },
        submit() {

                this.axios.post(this.baseUrl + 'forgot-password', {
                        email: this.email,
                    }).then(function (data) {

                        if (data.data.error == true)
                            this.errors = data.data.message;
                        else this.success=data.data.message;
                        
                    }.bind(this));
        }
    },
}
</script>
