import { VueCookieNext } from 'vue-cookie-next'
import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import RankingAndReward from '../views/RankingAndReward.vue'
import MyAccount from '../views/MyAccount.vue'
import MyWallet from '../views/MyWallet.vue'
import ResultHistory from '../views/ResultHistory.vue'
import ContactUs from '../views/ContactUs.vue'
import BetNow from '../views/BetNow.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/ranking-and-reward',
        name: 'RankingAndReward',
        component: RankingAndReward,
    },
    {
        path: '/my-account',
        name: 'MyAccount',
        component: MyAccount,
    },
    {
        path: '/my-wallet',
        name: 'MyWallet',
        component: MyWallet,
    },
    {
        path: '/result-history',
        name: 'ResultHistory',
        component: ResultHistory,
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        component: ContactUs,
    },
    {
        path: '/bet-now',
        name: 'BetNow',
        component: BetNow,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/sign-up',
        name: 'SignUp',
        component: SignUp,
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
     let user_id = VueCookieNext.getCookie("user_id")
        if (user_id == null && (to.fullPath!="/" && to.fullPath!="/login" && to.fullPath!="/sign-up" && to.fullPath!="/forgot-password" && to.path!="/reset-password")) {
        // user doesn't have access token, redirect to login
        next("/login")
    }
    else {
        // user has access token, user can open the page
        next()
    }
})


export default router
