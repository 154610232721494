<template>
<DefaultLayout>
    <template v-slot:header>
        <Header :type="'back'" :headerTitle=title :balanceStatus="''" />
    </template>
    <template v-slot:hero>
        <HeroMain :page="'resultHistory'"></HeroMain>
    </template>
    <template v-slot:content>
        <section class="wrapper-content">
            <div class="container">
                <div v-if="loaderActive" class="main-card normal">
                    <loader :active="loaderActive" />
                </div>
                <div v-else class="main-card normal">
                    <ul v-if="resultHistory.length>0" class="result-history">
                        <li v-for="(singleResult,index) in resultHistory" :key="index">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <p>{{singleResult.event.name}}, {{getTime(singleResult.event)}} {{ (singleResult.event.result)?(singleResult.event.result!='draw')?singleResult.event.winner.name:'Draw':''}} <br><span>(odds {{getOdds(singleResult.event,singleResult)}})</span></p>
                                </div>
                                <div>
                                    <p class="" :class="singleResult.betResult == 'N/A' ? 'default' : '' , singleResult.status == 'loss' ? 'loss' : '' ,singleResult.status == 'won' ? 'success' : ''">{{singleResult.status?singleResult.status:'Ongoing'}}</p>
                                </div>
                            </div>
                            <div class="mt-2 d-flex justify-content-between align-items-center">
                                <div>
                                    <p class="date"><span>Bet{{singleResult.betDone}} : {{singleResult.fund_used}} </span>{{singleResult.event.date}}</p>
                                </div>
                                <div>
                                    <p class="points " :class="!singleResult.status ? 'default' : '' , singleResult.status == 'loss' ? 'loss' : '' ,singleResult.status == 'won' ? 'success' : ''">    {{singleResult.status == 'won'?"+"+ singleResult.transaction.credit:(singleResult.status == 'loss')?'0 FP':'Wait...'}}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div v-else class="no-data">
                        There is no history
                    </div>
                </div>
            </div>
        </section>
    </template>
    <template v-slot:modals>
        <!-- Modal -->
        <div class="modal fade what-is-fp-modal modal-bg-img-sm" id="whatIsFP" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header align-items-center">
                        <h1 class="modal-title">What is FP?</h1>
                    </div>
                    <div class="modal-body">
                        <div class="inner-wrapper">
                            <p>FP is "Fifa Point" which you get
                                from winning bet using fund.
                                Your FP amount will determine
                                your ranking to compete
                                with other players on who has
                                the highest FP.
                            </p>
                            <p>
                                You can convert FP into Funds to
                                try win a bigger amount when
                                needed.
                            </p>
                        </div>
                    </div>
                    <div class="px-4 pb-4">
                        <button data-dismiss="modal" type="button" class="btn btn-purple btn-full">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade convert-to-fund-modal" id="convertToFundModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <h6>Current Balance</h6>
                        <h1>100 <span>FP</span></h1>
                        <p>10 FP = 1 Fund</p>
                        <form class="form" action="">
                            <div class="form-group m-0">
                                <input class="form-control" type="text" placeholder="Min. 500FP">
                            </div>
                            <div class="form-group m-0">
                                <span><i class="fa fa-angle-down"></i></span>
                            </div>
                            <div class="form-group">
                                <input class="form-control convert-fund-field" type="text" placeholder="" value="100 Fund">
                                <p class="mt-2">Conversion cannot be reversed</p>
                            </div>

                            <div class="form-group pt-4">
                                <button data-dismiss="modal" data-toggle="modal" data-target="#conversionDoneModal" type="button" class="btn btn-purple btn-full">Convert</button>
                                <button type="button" class="btn btn-outline btn-full mt-2" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade conversion-done-modal" id="conversionDoneModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header justify-content-center border-0">
                        <h1 class="modal-title m-0">Conversion Done!</h1>
                    </div>
                    <div class="modal-body text-center">
                        <img src="../assets/img/check-icon.svg" alt="Check-icon">
                        <h6 class="mt-5">You now have</h6>
                        <h5>100 FUNDS</h5>
                    </div>
                    <div class="px-4 pb-4 pt-2">
                        <button type="button" class="btn btn-purple btn-full mt-2" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </template>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
// @ is an alias to /src
import SidebarNav from '@/components/SidebarNav.vue'
import Header from '@/components/Header.vue'
import HeroMain from '@/components/HeroMain.vue'
import Loader from '../components/Loader.vue'
import config from '../config'

export default {
    name: 'ResultHistory',
    data() {
        return {
            menuShow: false,
            userLogin: true,
            loaderActive: true,
            title: "result history",
            $baseUrl: '',
            resultHistory: []
        }
    },
    components: {
        DefaultLayout,
        SidebarNav,
        HeroMain,
        Header,
        Loader
    },
    mounted() {
        this.baseUrl = config.$baseUrl;
        this.fetchResultHistroy();
    },
    methods: {
        toggleMenu() {
            this.menuShow = true
        },

        getTime(event) {
            return event.time
        },

        getOdds(event, bet) {
            if (bet.bet_option == event.team_one)
                return event.odd_one
            else if (bet.bet_option == event.team_two)
                return event.odd_two;
            else if (bet.bet_option == 'draw')
                return event.draw;
        },

        fetchResultHistroy() {

            const token = this.$cookie.getCookie('sanctum_token');

            this.axios.get(this.baseUrl + 'get-result-history', {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {

                    this.resultHistory = response.data;
                    this.loaderActive=false;

                }.bind(this))
        }

    },
}
</script>
