<template>
<DefaultLayout>
    <template v-slot:header>
        <Header :type="''" :headerTitle=title :balanceStatus="''" />
    </template>
    <template v-slot:hero>
        <HeroMain :page="'login'"></HeroMain>
    </template>
    <template v-slot:content>
        <section class="wrapper-content">
            <div class="container">
                <div v-if="this.$cookie.getCookie('message')" class="message">
                    <div class="alert alert-success">{{ this.$cookie.getCookie('message') }}</div>
                </div>
                <div v-if="errors.length>0" class="message">
                    <div class="alert alert-danger">{{ errors }}</div>
                </div>
                <form class="form login-form" action="">
                    <div class="form-group">
                        <label for="userEmail">Email/Username</label>
                        <input required v-model="email" class="form-control" type="text" name="userEmail" id="userEmail" placeholder="Username/email">
                    </div>
                    <div class="form-group">
                        <label for="userPassword">Password</label>
                        <div class="position-relative">
                            <input placeholder="Password" required v-model="password" class="form-control" :type="type" name="userPassword" id="userPassword">
                            <span role="button" @click="eye()" class="eye-icon">
                                <img src="../assets/img/eye-icon.svg" alt="">
                            </span>
                        </div>

                    </div>
                    <div class="form-group">
                        <!-- <label for="userPassword">I am not a Robot</label> -->
                        <div class="position-relative">
                            <VueRecaptcha height="60px" sitekey="6Ld2-MQgAAAAAKrb6Sl9QS7vHqe1qEkcOe0PJHkv" @verify="handleSuccess" @error="handleError">
                            </VueRecaptcha>
                        </div>

                    </div>
                    <div class="form-group text-right">
                        <router-link to="/forgot-password" class="forget-password-link">Forgot Password?</router-link>
                    </div>
                    <div class="form-group">
                        <button v-if="verify" type="submit" @click.stop.prevent="submit()" class="btn btn-purple btn-full">Login</button>
                        <button v-else type="submit" @click.stop.prevent="notVerify()" class="btn btn-purple btn-full mt-2">Login</button>
                        <router-link to="/sign-up" class="btn btn-outline btn-full mt-2">Register</router-link>
                    </div>
                </form>
            </div>

        </section>
    </template>
    <template v-slot:modals>
        <!-- Modal -->
    </template>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
// @ is an alias to /src
import SidebarNav from '@/components/SidebarNav.vue'
import Header from '@/components/Header.vue'
import HeroMain from '@/components/HeroMain.vue'
import config from '../config'

// google recaptcha
import {
    computed,
    defineComponent
} from 'vue';
import {
    VueRecaptcha
} from 'vue-recaptcha';

export default {
    name: 'Login',
    data() {
        return {
            menuShow: false,
            userLogin: true,
            baseUrl: "",
            verify: false,
            errors: '',
            type: 'password',
            title: "",
            email: "",
            password: ""
        }
    },
    components: {
        DefaultLayout,
        VueRecaptcha,
        SidebarNav,
        HeroMain,
        Header
    },
    mounted() {
        this.baseUrl = config.$baseUrl
    },
    methods: {
        notVerify()
        {
            this.errors="Please verify that you are human";
        },
        handleError() {
            this.errors = "You are failed to verify the recaptcha";
        },
        handleSuccess() {
            this.verify=true;
        },
        eye() {
            if (this.type == 'password')
                this.type = 'text';
            else if (this.type == 'text')
                this.type = 'password';

        },
        toggleMenu() {
            this.menuShow = true
        },
        submit() {

            if (this.email != '' && this.password != '') {
                this.axios.post(this.baseUrl + 'login', {
                        email: this.email,
                        password: this.password
                    }).then(function (data) {

                        if (data.data.error == true) {
                            this.errors = data.data.message;
                        } else {

                            const user_data = data.data.user;
                            document.cookie = "sanctum_token=" + data.data['access_token'];
                            document.cookie = "user_image=" + data.data['user_image'];
                            document.cookie = "user_id=" + user_data['id'];
                            document.cookie = "user_name=" + user_data['username'];
                            document.cookie = "user_data=" + JSON.stringify(user_data);
                            this.$cookie.removeCookie('message')

                            this.$router.push("/");
                        }
                    }.bind(this));
            } else this.errors = "Email/Password can not be empty";
        }
    },
}
</script>
